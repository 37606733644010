<template>
  <div class="content error404">
    <a href="/">
    <button >BACK TO HOME</button>
    </a>
  </div>
</template>

<script>
import ButtonHome from '@/components/Button/Button.vue'

export default {
  name: 'Error404',
  components: {
    ButtonHome,
  },
}
</script>
